.App {
  text-align: center;
}

.header-font-white {
  color: white;
}

#navbar {
  position: sticky;
  zIndex: 1003;
  backgroundColor: #16191f;
}

/*This CSS hide the signUp tab*/
.amplify-tabs {
  display: none;
}



/* This adjust the SignIn font size */
:root{
--amplify-components-fieldcontrol-small-font-size: 1.5rem;
--amplify-components-button-font-size: 1.5rem;
--amplify-components-fieldcontrol-font-size: 1.5rem;
--amplify-components-tabs-item-font-size: 1.5rem;
}

/*
.amplify-button[data-variation='primary'] {
  background: linear-gradient(
    to right,
    var(--amplify-colors-green-80),
    var(--amplify-colors-orange-40)
  );
}
*/


/* styles.css */
/*[data-amplify-theme] {
  --amplify-components-button-primary-background-color: #0057ff;
  --amplify-components-button-primary-hover-background-color: #4d89fc;
}*/

/* OR */
/*.amplify-button {
  background-color: #0057ff;
} */

.amplify-button:hover {
  background-color: #08a4dd;
}

.amplify-button[data-variation='primary'] {
  background-color: #08a4dd;
}